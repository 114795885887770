@import '../../styles/common/common.scss';

.card {
    color: var(--utmb-color-default);
    background-color: inherit;
    margin-bottom: 1rem;
    position: relative;

    & a {
        text-decoration: none;
    }

    @include bp(lg) {
        margin-bottom: 2rem;
    }
}

.card_thumbnail {
    position: relative;

    img {
        z-index: 1;
    }

    .card_overlay {
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        transition: all 0.3s ease-out;
        mix-blend-mode: darken;
        background-color: var(--utmb-color-ws-secondary);
    }

    .share_panel_closed {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: rem(60px);
        padding: 0 rem(10px) 0 rem(20px);
        background-color: var(--utmb-color-white);
        display: flex;
        align-items: center;
        justify-content: space-between;
        opacity: 0;
        transition: all 0.3s ease-out;

        .share_label {
            text-transform: uppercase;
        }

        .share_icons {
            & a {
                &:not(:first-child) {
                    margin-left: rem(15px);
                }
            }
        }

        .share_close_btn {
            text-transform: uppercase;
        }
    }

    .share_panel_open {
        opacity: 1;
        box-shadow: 0 rem(15px) rem(20px) rem(5px) rgba(0, 0, 0, 0.15);
        transform: translateY(60px);
    }

    &:hover {
        .card_overlay {
            opacity: 0.5;
        }
    }

    .card_icon {
        z-index: 1;
        position: absolute;
        width: rem(35px);
        height: rem(35px);
        bottom: 0;
        right: 0;

        svg {
            width: rem(35px);
            height: rem(35px);
        }
    }
}

.card_content {
    min-height: 8rem;
    margin: rem(20px) rem(10px) 0;
    z-index: -10;

    .header_content {
        display: flex;
        justify-content: space-between;
    }

    .card_date {
        text-transform: uppercase;
        line-height: 1.43;
        letter-spacing: 0.61px;
    }

    .card_title {
        text-transform: uppercase;
        line-height: rem(20px);
        margin: 0 0;
        font-weight: 900;
        letter-spacing: rem(1px);
        margin-top: rem(8px);
        margin-bottom: rem(20px);

        @include bp(lg) {
            line-height: rem(25px);
        }
    }

    .card_summary {
        line-height: 1.43;
        padding-bottom: 2rem;
        margin-top: rem(15px);
    }
}

.share_panel_icon {
    margin-right: rem(5px);
}

.card_link {
    &:link,
    &:visited,
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }
}
