@import '../../styles/common/common.scss';

.container {
    margin-top: var(--component-unhook-height);
    margin-bottom: var(--component-unhook-height);
}

.toolbar {
    display: flex;
    width: 100%;
    align-items: center;
    border: rem(1px) solid transparent;
    position: relative;
    margin-bottom: rem(32px);
    flex-direction: column;

    .filter_label {
        display: block;
        text-transform: capitalize;
        text-align: left;

        .filter_label_value {
            display: flex;
            color: var(--utmb-color-primary);
        }

        .filter_label_icon {
            padding: 0 rem(2px);
        }
    }

    @include bp(lg) {
        flex-direction: row;
    }

    .filter_content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 2px;
    }
}

.toolbar > div:not(:last-child) {
    border-right: rem(1px) solid #c9ced1 !important;
}

.drop {
    --popover-offset: #{rem(8px)};
    --popover-padding: #{rem(22px)};

    color: var(--utmb-color-default);

    background: var(--utmb-color-gray);

    > button {
        cursor: pointer !important;
    }

    .reset_button {
        background: none;
        border: none;
        cursor: pointer;
        margin-left: rem(5px);
    }

    &:hover {
        cursor: pointer;
        background: var(--utmb-color-gray-hover);
    }

    [data-reach-menu],
    [data-reach-menu-popover] {
        z-index: 3;
        position: absolute;
        width: 100%;
        top: calc(60px + var(--popover-offset)) !important;
        box-shadow: var(--utmb-filters-dropdowns);
        background-color: var(--utmb-color-beige);
    }

    [data-reach-menu-button] {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: var(--popover-padding);
    }

    [data-reach-menu-popover] [data-reach-menu-item] {
        border: none !important;
    }

    [data-reach-menu-items] {
        background-color: var(--utmb-color-background);
        max-height: 300px !important;
        overflow: auto !important;
    }

    [data-selected] {
        background: var(--utmb-color-gray-hover);
    }
}

.drop_type {
    [data-reach-menu-items] {
        background-color: var(--utmb-color-light-grey);
        max-height: rem(300px) !important;
        overflow: inherit !important;
    }
}

/* DROPDOWN ITEM */
.item {
    background-color: var(--utmb-color-background);
    color: var(--utmb-color-default);
    padding: var(--popover-padding);
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
        width: rem(19px);
        height: rem(19px);
        border: rem(1px) solid rgba(65, 80, 92, 0.2);
        border-radius: rem(3px);
        position: relative;

        input[type='checkbox'] {
            opacity: 0;
        }
        svg {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
        }
    }
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: rem(44px);
    margin-bottom: rem(141px);
}

.trigger {
    &:focus {
        outline: none;
    }
    &[aria-expanded='true'] {
        svg {
            transform: rotate(270deg) !important;
        }
    }
    &[aria-expanded='false'] {
        svg {
            transform: rotate(90deg) !important;
        }
    }
    appearance: none;
    border: 0;
    box-shadow: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: var(--utmb-color-beige);
    width: 100%;
    height: rem(60px);
    padding: rem(21px) rem(25px) rem(19px) rem(30px);
}

.masonry_grid {
    display: grid;
    gap: rem(20px);
    grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
    grid-auto-rows: 32;
}

// react-masonry-css
.my_masonry_grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: rem(-30px);
    width: auto;
}

.my_masonry_grid_column {
    padding-left: rem(30px);
    background-clip: padding-box;
}

.input_text {
    background-color: var(--utmb-color-gray);
    height: auto;
    & input {
        width: 100%;
        border: none;
        font-size: rem(14px);
        padding: rem(24px);
        font-family: 'Oxanium';
        background-color: transparent;
        line-height: rem(44px);

        &:hover,
        &:focus {
            background: var(--utmb-color-gray-hover);
        }
    }
}

.loading_container {
    position: relative;
    width: 100%;
    height: rem(400px);
}
